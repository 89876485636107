import { render, staticRenderFns } from "./editBulletin.vue?vue&type=template&id=3dbb332e&scoped=true&lang=pug"
import script from "./editBulletin.vue?vue&type=script&lang=js"
export * from "./editBulletin.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3dbb332e",
  null
  
)

/* custom blocks */
import block0 from "./editBulletin.vue?vue&type=custom&index=0&blockType=router"
if (typeof block0 === 'function') block0(component)

export default component.exports